.Freight-area{
  padding: 100px 0;
}
.contact-us-area{
  background: url('../../images/air/air-fraight.jpg') no-repeat center top / cover;
  position: relative;
  z-index: 1;
  padding: 30px 30px 20px;
}
.contact-us-area:before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(20,36,64,.8);
  z-index: -1;
}
.contact-us-area h3{
  color:#fff;
  font-size:24px;
  font-weight: 600;
  margin-bottom: 30px;
  padding-bottom: 15px;
  position: relative;
}
.contact-us-area h3:before{
  content: "";
  position: absolute;
  left: 0;
  bottom:0;
  width: 30px;
  height: 5px;
  background:#34ccff; 
}
.contact-s span{
  color:#fff;
  font-size: 15px;
}
.contact-s span i{
  padding-right:5px;
  color: #34ccff; 
}
.contact-s p{
  color:#fff;
  font-weight: 18px;
}
.Freight-item h3{
  font-size: 30px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 40px;
}
.Freight-item p{
  line-height: 30px;
  margin-bottom: 20px;
}
.Freight-item img{
  margin-bottom: 20px;

}
.Freight-s img{
  float: left;
  padding-right: 30px;
}
.Freight-s{
  overflow: hidden;
}
.Freight-s .f-s{
  overflow: hidden;
}
.Freight-s .f-s span{
  font-size: 16px;
   display: block;
   padding-bottom: 10px;
   padding-left: 25px;
   position: relative;
}

.Freight-s .f-s span:before{
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  width: 10px;
  height: 10px;
  background: #34ccff;
  border-radius: 50%;
}

.catagory-item {
  background: #f5f5f5;
  margin-top: 60px;
}

.catagory-item .widget-title {
  background: #ddd;
  padding: 10px 20px;
  position: relative;
}

.catagory-item .widget-title:before {
  content: '';
  position: absolute;
  left: 30px;
  top: 100%;
  border: 15px solid transparent;
  border-top-width: 13px;
  border-top-color: rgba(0,0,0,.1);
}

.catagory-item .widget-title h3 {
  font-size: 24px;
  text-align: center;
}
.category-section {
  padding: 10px 0px 10px;
}

.category-section ul li {
  border-bottom: 1px solid #ddd;
}

.category-section ul li:last-child{
  border-bottom:  none;
}
.category-section ul li a {
  font-size: 16px;
  color: #333;
  display: block;
  padding: 15px 20px 10px;
  transition: all .3s;
}

.price-sec .wpo-pricing-area{
  padding-top: 100px;
}