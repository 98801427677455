.about-ss {
   padding-left: 0;
   margin-left: -60px;
}
.wpo-about-style-3  {
 background: #fff;
}

.wpo-about-style-3   .about-wrap {
   background:#142440;
   padding: 50px;
   float: left;
   max-height: 580px;
   margin-top: 48px;
   z-index: 99;
}
.about-title .img-holder img{
   border:10px solid #34ccff;

}
.about-title .img-holder {
   position: relative;
}
.social-1st2 {
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   transition: all .3s;
   display: flex;
   justify-content: center;
   flex-direction: column;
   text-align: center;
   z-index: 999;
}

.social-1st2 i {
   border: 1px solid #fff;
   padding: 20px;
   border-radius: 100%;
   color: #fff;
   font-size: 25px;
}
.social-1st2 .wrap{
   background: transparent;
   border: none;
}

